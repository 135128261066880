import React from "react";
import Markdoc from "@markdoc/markdoc";
import "./openApi.styles.css";

const useOutsideClick = (callback: () => void) => {
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return ref;
};

const useLocalStorage = (
  key: string,
  initial_value: string | number,
  session_only = false,
): [string | number, (value: string | number | null) => void] => {
  const storage = session_only ? window.sessionStorage : window.localStorage;

  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = storage.getItem(key);
      return item !== null && item !== undefined
        ? JSON.parse(item)
        : initial_value;
    } catch (error) {
      console.log(key, error);
      return initial_value;
    }
  });

  const setValue = (value: string | number | null) => {
    try {
      setStoredValue(value);
      storage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(key, error);
    }
  };
  return [storedValue, setValue];
};

class OpenApiHelpers {
  private _schema: any;

  constructor(schema: any) {
    this._schema = schema;
  }

  public isRef(node: any): boolean {
    return !!node["$ref"];
  }

  public resolveRef(node: any): any {
    return !this.isRef(node)
      ? node
      : node["$ref"]
          .split("/")
          .splice(1)
          .reduce(
            (obj: any, part: string) => (obj ? obj[part] : obj),
            this._schema,
          );
  }

  public getRefTypeName(node: any): string | undefined {
    return this.isRef(node) ? node["$ref"].split("/").pop() : undefined;
  }

  public getTypeName(node: any, includeSuffix = false): string {
    let suffix = "";

    if (this.isRef(node)) {
      const resolvedNode = this.resolveRef(node);

      if (!!resolvedNode["x-docs-type"]) return resolvedNode["x-docs-type"];

      if (resolvedNode.anyOf)
        suffix +=
          " (Any of: " + this.getTypeNames(resolvedNode).join(", ") + ")";
      else if (resolvedNode.oneOf)
        suffix +=
          " (One of: " + this.getTypeNames(resolvedNode).join(", ") + ")";
      else suffix += " (" + this.getTypeName(resolvedNode) + ")";

      return (this.getRefTypeName(node) + (includeSuffix ? suffix : "")).trim();
    }

    if (!!node["x-docs-type"]) return node["x-docs-type"];

    if (node.anyOf) return this.getTypeNames(node).join(", ");

    if (node.oneOf) return this.getTypeNames(node).join(" | ");

    if (this.isEnum(node)) suffix += " (enum)";

    if (this.hasFormat(node)) suffix += ` (${node.format})`;

    if (node.type === "array") {
      let itemType;

      if (this.isRef(node.items))
        itemType =
          this.resolveRef(node.items)["x-docs-type"] ??
          this.getRefTypeName(node.items);
      else if (!!node.items.anyOf || !!node.items.oneOf) itemType = "items";
      else itemType = this.getTypeName(node.items);

      return `${itemType}[]`;
    }

    return (node.type + (includeSuffix ? suffix : "")).trim();
  }

  public getTypeNames(node: any): string[] {
    if (node.anyOf)
      return node.anyOf.flatMap((childNode: any) =>
        this.getTypeNames(childNode),
      );

    if (node.oneOf)
      return node.oneOf.flatMap((childNode: any) =>
        this.getTypeNames(childNode),
      );

    return [this.getTypeName(node)];
  }

  public isEnum(node: any): boolean {
    const enumValues = this.getEnumValues(node);
    return !!enumValues && enumValues.length > 0;
  }

  public getEnumValues(node: any): any[] | undefined {
    const allValues = this.resolveRef(node).enum;
    if (allValues) {
      // remove case-insensitive duplicates, keep the original if not duplications
      const map = new Map<string, string>();
      for (const item of allValues) {
        const lowerCaseItem = item.toLowerCase();
        if (!map.has(lowerCaseItem) || item === item.toUpperCase()) {
          map.set(lowerCaseItem, item);
        }
      }
      return Array.from(map.values());
    }
    return allValues;
  }

  public hasFormat(node: any): boolean {
    return !!this.getFormat(node);
  }

  public getFormat(node: any): string | undefined {
    return this.resolveRef(node).format;
  }

  public isRequired(schema: any, name: string) {
    return schema.required?.indexOf(name) != -1;
  }

  public isNullable(node: any) {
    return !!this.resolveRef(node).nullable;
  }

  public isSimpleType(node: any) {
    const resolvedNode = this.resolveRef(node);

    return (
      resolvedNode["x-docs-force-simple-type"] ||
      ((!resolvedNode.anyOf ||
        (!!resolvedNode.anyOf &&
          resolvedNode.anyOf.filter((s: any) => this.isVisible(s)).length ===
            0)) &&
        (!resolvedNode.oneOf ||
          (!!resolvedNode.oneOf &&
            resolvedNode.oneOf.filter((s: any) => this.isVisible(s)).length ===
              0)) &&
        (resolvedNode.type !== "array" ||
          (resolvedNode.type === "array" &&
            !this.isVisible(resolvedNode.items))) &&
        (resolvedNode.type !== "object" ||
          (resolvedNode.type === "object" &&
            Object.keys(resolvedNode.properties).filter((s: any) =>
              this.isVisible(resolvedNode.properties[s]),
            ).length === 0)))
    );
  }

  public isVisible(node: any) {
    const resolvedNode = this.resolveRef(node);
    return !resolvedNode["x-docs-hide"];
  }
}

const OpenApiSpecContext = React.createContext(null);

const Markdown = ({ body }: { body: string }) => {
  const ast = Markdoc.parse(body);
  const content = Markdoc.transform(ast, {
    nodes: {
      document: {
        render: undefined,
      },
    },
  });
  const html = Markdoc.renderers.html(content);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const EnumValues = ({
  values,
  preview,
}: {
  values: string[];
  preview: number;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const showValues = (isExpanded ? values : values.slice(0, preview)).map(
    (val, idx) => (
      <span key={idx}>
        <code>{val}</code>
      </span>
    ),
  );

  if (values.length <= preview) return showValues;

  const hiddenValuesCount = values.length - preview;

  return (
    <>
      {showValues}
      {isExpanded && (
        <button className="toggle-button" onClick={() => setIsExpanded(false)}>
          show less
        </button>
      )}
      {!isExpanded && (
        <button className="toggle-button" onClick={() => setIsExpanded(true)}>
          ... show {hiddenValuesCount} more
        </button>
      )}
    </>
  );
};

function InlineTabs({
  options,
}: {
  options: { name: string; content: string }[];
}) {
  const [selectedIdx, setSelectedIdx] = React.useState(0);
  return (
    <>
      <div className="inline-tab-container">
        {options.map((option, idx) => (
          <button
            className={`inline-tab-option ${
              idx === selectedIdx ? "active" : ""
            }`}
            key={idx}
            onClick={() => setSelectedIdx(idx)}>
            {option.name}
          </button>
        ))}
      </div>
      <div className="inline-tab-content">{options[selectedIdx].content}</div>
    </>
  );
}

function EnumSelect({
  options,
}: {
  options: { name: string; content: string }[];
}) {
  const [selectedIdx, setSelectedIdx] = useLocalStorage("temp", 0);

  if ((selectedIdx as number) >= options.length) {
    setSelectedIdx(0);
  }

  const [open, setOpen] = React.useState(false);
  const [search_value, setSearchValue] = React.useState("");

  const ref = useOutsideClick(() => setOpen(false));

  return (
    <>
      <div className="inline-tab-container">
        <button className="select" onClick={() => setOpen(!open)}>
          {options[selectedIdx as number].name}
          <span>
            Select{" "}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M7.99792 12.7L10.5313 10.1667C10.6535 10.0445 10.7951 9.98334 10.9563 9.98334C11.1174 9.98334 11.259 10.0445 11.3813 10.1667C11.5035 10.2889 11.5646 10.4306 11.5646 10.5917C11.5646 10.7528 11.5035 10.8945 11.3813 11.0167L8.84792 13.55C8.61458 13.7833 8.33125 13.9 7.99792 13.9C7.66459 13.9 7.38125 13.7833 7.14792 13.55L4.61458 11.0167C4.49236 10.8945 4.43403 10.7528 4.43958 10.5917C4.44514 10.4306 4.50903 10.2889 4.63125 10.1667C4.75347 10.0445 4.89514 9.98334 5.05625 9.98334C5.21736 9.98334 5.35903 10.0445 5.48125 10.1667L7.99792 12.7ZM7.99792 3.30001L5.46458 5.83334C5.34236 5.95556 5.2007 6.01667 5.03958 6.01667C4.87847 6.01667 4.73681 5.95556 4.61458 5.83334C4.49236 5.71112 4.43125 5.56945 4.43125 5.40834C4.43125 5.24723 4.49236 5.10556 4.61458 4.98334L7.14792 2.45001C7.38125 2.21667 7.66459 2.10001 7.99792 2.10001C8.33125 2.10001 8.61458 2.21667 8.84792 2.45001L11.3813 4.98334C11.5035 5.10556 11.5646 5.24445 11.5646 5.40001C11.5646 5.55556 11.5035 5.69445 11.3813 5.81667C11.259 5.9389 11.1174 6.00001 10.9563 6.00001C10.7951 6.00001 10.6535 5.9389 10.5313 5.81667L7.99792 3.30001Z" />
            </svg>
          </span>
        </button>
        {open && (
          <div className="select-dropdown" ref={ref as any}>
            <div className="select-dropdown-header">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M6.33333 10.6667C5.12222 10.6667 4.09722 10.2472 3.25833 9.40833C2.41944 8.56944 2 7.54444 2 6.33333C2 5.12222 2.41944 4.09722 3.25833 3.25833C4.09722 2.41944 5.12222 2 6.33333 2C7.54444 2 8.56944 2.41944 9.40833 3.25833C10.2472 4.09722 10.6667 5.12222 10.6667 6.33333C10.6667 6.82222 10.5889 7.28333 10.4333 7.71667C10.2778 8.15 10.0667 8.53333 9.8 8.86667L13.5333 12.6C13.6556 12.7222 13.7167 12.8778 13.7167 13.0667C13.7167 13.2556 13.6556 13.4111 13.5333 13.5333C13.4111 13.6556 13.2556 13.7167 13.0667 13.7167C12.8778 13.7167 12.7222 13.6556 12.6 13.5333L8.86667 9.8C8.53333 10.0667 8.15 10.2778 7.71667 10.4333C7.28333 10.5889 6.82222 10.6667 6.33333 10.6667ZM6.33333 9.33333C7.16667 9.33333 7.875 9.04167 8.45833 8.45833C9.04167 7.875 9.33333 7.16667 9.33333 6.33333C9.33333 5.5 9.04167 4.79167 8.45833 4.20833C7.875 3.625 7.16667 3.33333 6.33333 3.33333C5.5 3.33333 4.79167 3.625 4.20833 4.20833C3.625 4.79167 3.33333 5.5 3.33333 6.33333C3.33333 7.16667 3.625 7.875 4.20833 8.45833C4.79167 9.04167 5.5 9.33333 6.33333 9.33333Z" />
              </svg>
              <input
                autoFocus
                placeholder="Search..."
                value={search_value}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className="select-dropdown-content">
              {options.map((option, idx) => {
                if (
                  search_value &&
                  !option.name
                    .toLowerCase()
                    .includes(search_value.toLowerCase())
                ) {
                  return;
                }
                return (
                  <button
                    className={`select-droptown-option`}
                    key={idx}
                    onClick={() => {
                      setSelectedIdx(idx);
                      setOpen(false);
                      setSearchValue("");
                    }}>
                    {option.name}
                  </button>
                );
              })}
              <span>No option match this search term.</span>
            </div>
          </div>
        )}
      </div>
      <div className="inline-tab-content">
        {options[selectedIdx as number].content}
      </div>
    </>
  );
}

function OpenApiDescription({
  schema,
  isRequired,
  name,
}: {
  schema: any;
  isRequired: boolean;
  name?: string;
}) {
  const spec = React.useContext(OpenApiSpecContext);
  const oa = new OpenApiHelpers(spec);
  const resolvedSchema = oa.isRef(schema) ? oa.resolveRef(schema) : schema;
  const typeName = oa.getTypeName(schema);
  const isEnum = oa.isEnum(schema);
  const enumValues = isEnum ? oa.getEnumValues(schema) : [];
  const options = [
    ...(resolvedSchema.anyOf || []),
    ...(resolvedSchema.oneOf || []),
  ];
  const isNullable =
    oa.isNullable(schema) || !!options?.find((o) => oa.resolveRef(o)?.nullable);

  return (
    <>
      <div className="property-row">
        {name && <p>{name}</p>}
        {typeName.split(", ").map((part) => (
          <code key={part}>{part}</code>
        ))}
        <div>
          {isRequired && <div className="format-span">Required</div>}
          {isNullable && <div className="format-span">Nullable</div>}
          {oa.hasFormat(schema) && (
            <div className="format-span">
              {oa.getFormat(schema)!.replace("date-time", "Date")}
            </div>
          )}
          {!!resolvedSchema.pattern && (
            <div className="format-span">Pattern: {resolvedSchema.pattern}</div>
          )}
          {!!resolvedSchema.minimum && (
            <div className="format-span">Min: {resolvedSchema.minimum}</div>
          )}
          {!!resolvedSchema.maximum && (
            <div className="format-span">Max: {resolvedSchema.maximum}</div>
          )}
          {!!resolvedSchema.minLength && (
            <div className="format-span">
              Min length: {resolvedSchema.minLength}
            </div>
          )}
          {!!resolvedSchema.maxLength && (
            <div className="format-span">
              Max length: {resolvedSchema.maxLength}
            </div>
          )}
        </div>
      </div>
      {!!resolvedSchema.description && (
        <div className="description">
          <Markdown body={resolvedSchema.description} />
        </div>
      )}
      {isEnum && enumValues && (
        <div className="description">
          <p>
            <div className="values">
              Allowed value{enumValues.length === 1 ? "" : "s"}
            </div>
            <div className="property-row">
              {<EnumValues values={enumValues} preview={5} />}
            </div>
          </p>
        </div>
      )}
    </>
  );
}

const PropertyTableRow = ({
  name,
  schema,
  isRequired,
}: {
  name: string;
  schema: any;
  isRequired: boolean;
}) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const spec = React.useContext(OpenApiSpecContext);
  const oa = new OpenApiHelpers(spec);
  const resolvedSchema = oa.isRef(schema) ? oa.resolveRef(schema) : schema;

  const isSimpleType = oa.isSimpleType(schema);
  return (
    <div className="property-table-row">
      <OpenApiDescription name={name} schema={schema} isRequired={isRequired} />
      {!isSimpleType && (
        <button
          className="clickable-expand"
          onClick={() => {
            setExpanded(!isExpanded);
          }}>
          {isExpanded ? "- Hide child parameters" : "+ Show child parameters"}
        </button>
      )}
      {!isSimpleType && isExpanded && (
        <div className="indented-div">
          <OpenApiSchema schema={resolvedSchema} />
        </div>
      )}
    </div>
  );
};

const OpenApiSchema = ({ schema }: { schema: any }) => {
  const spec = React.useContext(OpenApiSpecContext);
  const oa = new OpenApiHelpers(spec);
  const resolvedSchema = oa.isRef(schema) ? oa.resolveRef(schema) : schema;

  if (!oa.isVisible(resolvedSchema)) return null;

  if (!!resolvedSchema.anyOf) {
    if (resolvedSchema.anyOf.filter((s: any) => oa.isVisible(s)).length === 0) {
      return null;
    }

    const visibleOptions = resolvedSchema.anyOf
      .filter((s: any) => oa.isVisible(s))
      .map((s: any) => ({
        name: oa.getTypeName(s),
        content: <OpenApiSchema schema={s} />,
      }));

    if (visibleOptions.length > 6) {
      return <EnumSelect options={visibleOptions} />;
    }

    return <InlineTabs options={visibleOptions} />;
  }

  if (!!resolvedSchema.oneOf) {
    if (resolvedSchema.oneOf.filter((s: any) => oa.isVisible(s)).length === 0) {
      return null;
    }

    const visibleOptions = resolvedSchema.oneOf
      .filter((s: any) => oa.isVisible(s))
      .map((s: any) => ({
        name: oa.getTypeName(s),
        content: <OpenApiSchema schema={s} />,
      }));

    if (visibleOptions.length > 6) {
      return <EnumSelect options={visibleOptions} />;
    }

    return (
      <InlineTabs
        options={resolvedSchema.oneOf
          .filter((s: any) => oa.isVisible(s))
          .map((s: any) => ({
            name: oa.getTypeName(s),
            content: <OpenApiSchema schema={s} />,
          }))}
      />
    );
  }

  switch (resolvedSchema.type) {
    case "array":
      if (!oa.isVisible(resolvedSchema.items)) return <code>Array</code>;

      return <OpenApiSchema schema={resolvedSchema.items} />;

    case "object":
      if (
        Object.keys(resolvedSchema.properties).filter((propName) =>
          oa.isVisible(resolvedSchema.properties[propName]),
        ).length === 0
      )
        return (
          <>
            {
              <OpenApiDescription
                schema={schema}
                isRequired={!!schema.required}
              />
            }
          </>
        );

      return (
        <div>
          {Object.keys(resolvedSchema.properties)
            .filter((propName) =>
              oa.isVisible(resolvedSchema.properties[propName]),
            )
            .map((propName) => (
              <PropertyTableRow
                key={propName}
                name={propName}
                schema={resolvedSchema.properties[propName]}
                isRequired={
                  resolvedSchema.required &&
                  resolvedSchema.required.some((rp: any) => rp === propName)
                }
              />
            ))}
        </div>
      );

    default:
      return (
        <div className="property-table-row">
          <OpenApiDescription schema={schema} isRequired={!!schema.required} />
        </div>
      );
  }
};

export const OpenApiComponent = ({
  name,
  spec,
}: {
  name: string;
  spec: any;
}) => {
  const schema = spec.components.schemas[name];
  const oa = new OpenApiHelpers(spec);

  if (!schema) return <div>Could not resolve OpenAPI schema for {name}</div>;

  if (!oa.isVisible(schema)) return null;

  return (
    <OpenApiSpecContext.Provider value={spec}>
      <div className="open-api-schema">
        <OpenApiSchema schema={schema} />
      </div>
    </OpenApiSpecContext.Provider>
  );
};

export const OpenApiPath = ({
  method,
  path,
  spec,
}: {
  method: string;
  path: string;
  spec: any;
}) => {
  const schema =
    spec.paths[path] && spec.paths[path][method.toLocaleLowerCase()];

  const oa = new OpenApiHelpers(spec);

  if (!schema)
    return (
      <div>
        Could not resolve OpenAPI schema for {method.toLocaleUpperCase()} {path}
      </div>
    );

  var urlParams: any[] = schema.parameters.filter((p: any) => p.in === "path");
  var queryParams: any[] = schema.parameters.filter(
    (p: any) => p.in === "query" && oa.isVisible(p.schema),
  );
  var reqBodySchema =
    schema.requestBody &&
    schema.requestBody.content &&
    schema.requestBody.content["application/json"].schema;

  return (
    <div className="open-api-schema">
      <OpenApiSpecContext.Provider value={spec}>
        {urlParams.length > 0 && (
          <div>
            <h4>URL Parameters</h4>
            {urlParams.map((param) => (
              <PropertyTableRow
                key={param.name}
                name={param.name}
                schema={param.schema}
                isRequired={!!param.required}
              />
            ))}
          </div>
        )}

        {queryParams.length > 0 && (
          <div>
            <h4>Query Parameters</h4>
            {queryParams.map((param) => (
              <PropertyTableRow
                key={param.name}
                name={param.name}
                schema={param.schema}
                isRequired={!!param.required}
              />
            ))}
          </div>
        )}

        {reqBodySchema && oa.isVisible(reqBodySchema) && (
          <div>
            <h4>Body Parameters</h4>
            <OpenApiSchema schema={reqBodySchema} />
          </div>
        )}
      </OpenApiSpecContext.Provider>
    </div>
  );
};
